import "typeface-mada";
import { createMuiTheme } from "@material-ui/core/styles/index";

// CONST WG-COUCH COLORS
const PRIMARY_COLOR = "#FEC515";
const PRIMARY_COLOR_DARK = "#FFAA00";
const ALTERNATIVE_COLOR = "#BF009F";
const BACKGROUND_DARK = "#181834";
const PAPER = "#2D2D58";
const PAPER_ALTERANTIVE = "#2A2E43";

// XD to rem => Size * 0,075
const xdToRem = val => `${0.075 * val}rem`;

export const appTheme = createMuiTheme({
  wgCouch: {
    answerButton: {
      background: BACKGROUND_DARK,
      border: ALTERNATIVE_COLOR,
      selectedBackground: "linear-gradient(#870571 0%, #870571 100%);"
    },
    paper: {
      alternative: PAPER_ALTERANTIVE
    },
    textField: {
      backgroundColor: BACKGROUND_DARK
    }
  },
  typography: {
    xdToRem,
    fontFamily: "Mada,Roboto,sans-serif",
    body: {
      fontWeight: 400,
      color: "white",
      fontSize: xdToRem(11)
    },
    h5: {
      color: "white",
      fontWeight: 400,
      fontSize: xdToRem(14)
    },
    h6: {
      color: "white",
      fontWeight: 400,
      fontSize: xdToRem(16)
    }
  },
  palette: {
    type: "dark",
    primary: {
      main: ALTERNATIVE_COLOR,
      contrastText: "white"
    },
    secondary: {
      main: PRIMARY_COLOR,
      dark: PRIMARY_COLOR_DARK,
      contrastText: "white"
    },
    background: {
      default: BACKGROUND_DARK,
      paper: PAPER
    }
  },
  shape: {
    borderRadius: 10
  }
});
