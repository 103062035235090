import Raven from "raven-js";
import makeDebug from "debug";
import has from "lodash/has";

const debug = makeDebug("sentry");
let dsn = "";

if (process.env.NODE_ENV === "production") {
  dsn = "https://7bef67d1a5574e46868228225158d963@sentry.io/1288991";
  debug(
    `Sentry enabled. DSN: ${dsn}. App Version: ${
      process.env.REACT_APP_VERSION
    }. Environment: ${process.env.REACT_APP_ENVIRONMENT}`
  );
} else {
  debug("Not using sentry because of development mode");
}

const raven = Raven.config(dsn, {
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  release: process.env.REACT_APP_VERSION || "development"
}).install();

export function reportError(err, showReportDialog = false) {
  const isNetworkError =
    has(err, "crossDomain") && has(err, "method") && has(err, "url");
  if (process.env.NODE_ENV === "production") {
    debug(
      "Capture error with raven (sentry)",
      err,
      showReportDialog,
      isNetworkError
    );
    raven.captureException(err);
    if (showReportDialog && isNetworkError === false) {
      raven.showReportDialog();
    }
  } else {
    debug(
      "Don't capture error with raven (sentry) in non-production",
      err,
      showReportDialog,
      isNetworkError
    );
  }
}

export function handleReactError(error: Error, componentStack: string) {
  debug("Capture react error", error, componentStack);
  raven.captureException(error, {
    extra: { componentStack }
  });
}

export default raven;
