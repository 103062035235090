// @flow
// import makeDebug from "debug";
// const debug = makeDebug('ducks:createCouches');
import { reportError } from "lib/sentry";

const LOAD_APPLICATION = "LOAD_APPLICATION";
const SUBMIT_APPLICATION = "SUBMIT_APPLICATION";
const CONFIRM_APPLICATION = "CONFIRM_APPLICATION";

export type StateType = {
  application: ?Object,
  submittedApplicationId: ?string,
  isSubmitting: boolean,
  hasSubmitted: boolean,
  hasSubmitFailed: boolean,
  submitError: ?Object,
  isConfirming: boolean,
  hasConfirmed: boolean,
  hasConfirmedFailed: boolean,
  confirmError: ?Object
};

export const initialState: StateType = {
  application: null,
  submittedApplicationId: null,
  isSubmitting: false,
  hasSubmitted: false,
  hasSubmitFailed: false,
  submitError: null,
  // confirm
  isConfirming: false,
  hasConfirmed: false,
  hasConfirmedFailed: false,
  confirmError: null
};

// ------------------------------------ Actions ------------------------------------
export const loadApplication = (couchId: string) => {
  return (
    dispatch: Function,
    getState: Function,
    { getFirebase }: { getFirebase: Function }
  ) => {
    const getApplicationFunc = getFirebase()
      .functions()
      .httpsCallable("getApplication");
    const action = {
      type: LOAD_APPLICATION,
      payload: {
        promise: getApplicationFunc({ couchId })
      }
    };
    return dispatch(action);
  };
};

export const submitApplication = (
  couchId: string,
  personalInformation: { name: string, email: string },
  additionalDetails: { personalMessage: string },
  answers: Object
) => {
  return (
    dispatch: Function,
    getState: Function,
    { getFirebase }: { getFirebase: Function }
  ) => {
    const submitApplicationFunc = getFirebase()
      .functions()
      .httpsCallable("submitApplication");
    const action = {
      type: SUBMIT_APPLICATION,
      payload: {
        promise: submitApplicationFunc({
          couchId,
          personalInformation,
          answers,
          additionalDetails
        })
      }
    };
    return dispatch(action).catch(err => reportError(err));
  };
};

export const confirmApplication = token => {
  return (
    dispatch: Function,
    getState: Function,
    { getFirebase }: { getFirebase: Function }
  ) => {
    const confirmApplicationfunc = getFirebase()
      .functions()
      .httpsCallable("confirmApplication");
    const action = {
      type: CONFIRM_APPLICATION,
      payload: {
        promise: confirmApplicationfunc({ token })
      }
    };
    return dispatch(action).catch(err => reportError(err));
  };
};

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [`${LOAD_APPLICATION}_FULFILLED`]: (state, action) => ({
    ...state,
    application: action.payload.data
  }),
  [`${SUBMIT_APPLICATION}_PENDING`]: (state, action) => ({
    ...state,
    submittedApplicationId: null,
    isSubmitting: true,
    hasSubmitted: false,
    hasSubmitFailed: false
  }),
  [`${SUBMIT_APPLICATION}_FULFILLED`]: (state, action) => ({
    ...state,
    isSubmitting: false,
    hasSubmitted: true,
    hasSubmitFailed: false,
    submittedApplicationId: action.payload.data.applicationId
  }),
  [`${SUBMIT_APPLICATION}_REJECTED`]: (state, action) => ({
    ...state,
    isSubmitting: false,
    hasSubmitted: false,
    hasSubmitFailed: true,
    submitError: action.payload
  }),
  [`${CONFIRM_APPLICATION}_PENDING`]: (state, action) => ({
    ...state,
    submittedApplicationId: null,
    isConfirming: true,
    hasConfirmed: false,
    hasConfirmedFailed: false
  }),
  [`${CONFIRM_APPLICATION}_FULFILLED`]: (state, action) => ({
    ...state,
    isConfirming: false,
    hasConfirmed: true,
    hasConfirmedFailed: false
  }),
  [`${CONFIRM_APPLICATION}_REJECTED`]: (state, action) => ({
    ...state,
    isConfirming: false,
    hasConfirmed: false,
    hasConfirmedFailed: true,
    confirmError: action.payload
  })
};

// ------------------------------------ Reducer ------------------------------------
export default function applicationReducer(
  state: ?StateType = initialState,
  action: Object
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// ------------------------------------ Selectors ------------------------------------
