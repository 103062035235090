// @flow
import React from "react";

import { withStyles } from "@material-ui/core/styles/index";

export type NewVersionDialogProps = {
  newVersionAvailable: boolean,
  registration: ?Object,
  t: Function,
  classes: Object
};

const styles = theme => ({
  title: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing.unit
  }
});

function reload(registration) {
  if (!registration) {
    console.warn("No registration");
    return;
  }
  if (!registration.waiting) {
    // Just to ensure registration.waiting is available before
    // calling postMessage()
    return;
  }
  registration.waiting.postMessage("skipWaiting");
  // eslint-disable-next-line no-restricted-globals
  location.reload();
}

const NewVersionDialog = ({
  t,
  newVersionAvailable,
  registration,
  classes
}: NewVersionDialogProps) => <div>{reload(registration)}</div>;

export default withStyles(styles)(NewVersionDialog);
