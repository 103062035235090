import browserUpdate from "browser-update";

export default () => {
  const browserUpdateOptions = {
    notify: {
      i: 10,
      f: -4,
      o: -4,
      s: -2,
      c: -4
    },
    unsecure: true,
    api: 5,
    text:
      "Ihr Browser {brow_name}, ist leider veraltet. wg-couch wird deshalb nur eingeschränkt lauffähig sein. Bitte kontaktieren Sie uns, um gemeinsam eine Lösung zu finden. Oder aktualisieren Sie Ihren Browser: <a{up_but}>Browser aktualisieren</a>",
    noclose: true
  };
  browserUpdate(browserUpdateOptions);
};
