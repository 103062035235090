import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

import { isNewVersionAvailable, getServiceWorkerRegistration } from "ducks/app";
import NewVersionDialog from "./NewVersionDialog";

const mapStateToProps = state => ({
  newVersionAvailable: isNewVersionAvailable(state),
  registration: getServiceWorkerRegistration(state)
});

export default connect(mapStateToProps)(
  withNamespaces("newVersionDialog")(NewVersionDialog)
);
