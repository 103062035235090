// @flow
import makeDebug from "debug";
import amplitude from "./amplitude";

const debug = makeDebug("tracker");
class Tracker {
  track(eventName: string, props?: Object): void {
    debug("track event", eventName, props);
    setTimeout(() => {
      amplitude.logEvent(eventName, props, () =>
        debug("Successful tracked event", eventName, props)
      );
    });
  }

  identify(user: Object) {
    debug("Identify user", { user });
    setTimeout(() => {
      if (user.uid) {
        amplitude.setUserId(user.uid);
        const identify = new amplitude.Identify();
        identify.set("displayName", user.displayName);
        identify.set("email", user.email);
        amplitude.identify(identify);
      }
    });
  }

  // applicants
  trackApplicantOpenedApplicationPage(couchId: string) {
    this.track("applicant opened application page", { couchId });
  }
  trackApplicantSelectedAnswerInApplicationPage(couchId: string) {
    this.track("applicant selected question answer", { couchId });
  }
  trackApplicantSubmittedApplication(couchId: string) {
    this.track("applicant submited application", { couchId });
  }
  trackApplicantConfirmedApplication(couchId: string) {
    this.track("applicant confirmed application", { couchId });
  }
}

export default new Tracker();
