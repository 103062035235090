// @flow
export const NEW_VERSION_AVAILABLE = "NEW_VERSION_AVAILABLE";

export type StateType = {
  newVersionAvailable: boolean,
  registration: ?Object
};

export const initialState: StateType = {
  newVersionAvailable: false,
  registration: null
};

// ------------------------------------ Actions ------------------------------------
export const newVersionAvailable = (registration: Object) => ({
  type: NEW_VERSION_AVAILABLE,
  registration
});

// ------------------------------------ Action Handlers ------------------------------------
const ACTION_HANDLERS = {
  [NEW_VERSION_AVAILABLE]: (state, action) => ({
    ...state,
    newVersionAvailable: true,
    registration: action.registration
  })
};

// ------------------------------------ Reducer ------------------------------------
export default function appReducer(
  state: ?StateType = initialState,
  action: Object
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// ------------------------------------ Selectors ------------------------------------
// token
export const isNewVersionAvailable = ({ app }: { app: StateType }) =>
  app.newVersionAvailable;

export const getServiceWorkerRegistration = ({ app }: { app: StateType }) =>
  app.registration;
