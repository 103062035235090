import { compose } from "redux";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";
import ui from "react-redux-ui-tools";
import { withNamespaces } from "react-i18next";

import DashboardView from "./DashboardView";

import i18n from "i18n";
import de from "./i18n/de";
i18n.addResourceBundle("de", "dashboardView", de, true);

const mapStateToProps = (state, ownProps) => {
  return {};
};

const uiConfig = {
  key: "dashboardView",
  persist: true,
  state: {}
};

export default compose(
  ui(uiConfig),
  connect(mapStateToProps),
  withFirebase,
  withNamespaces("dashboardView")
)(DashboardView);
