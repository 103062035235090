import { compose } from "redux";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";
import { withNamespaces } from "react-i18next";
import { withSnackbar } from "notistack";

import ActionHandler from "./ActionHandler";

import i18n from "i18n";
import de from "./i18n/de";
i18n.addResourceBundle("de", "actionHandler", de, true);

export default compose(
  withRouter,
  withFirebase,
  withSnackbar,
  withNamespaces("actionHandler")
)(ActionHandler);
