import React from "react";
import styled from "styled-components";
import { withNamespaces } from "react-i18next";

import Button from "@material-ui/core/Button/index";
import Typography from "@material-ui/core/Typography/Typography";

const FullscreenLoaderStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MaxWidthTypography = styled(Typography)`
  max-width: 72ch;
`;

const GIFArea = styled.iframe`
  margin-bottom: 2rem;
`;

const AppError = ({ t }) => (
  <FullscreenLoaderStyle>
    <GIFArea
      title="cat gif"
      src="https://giphy.com/embed/3oKIPnAiaMCws8nOsE"
      width="457"
      height="480"
      frameBorder="0"
      className="giphy-embed"
      allowFullScreen
    />
    <MaxWidthTypography
      color="textSecondary"
      align="center"
      gutterBottom
      variant="subheading"
    >
      {t("AppError.subtitle")}
    </MaxWidthTypography>
    <Button
      component={props => (
        <a href="https://kultify.de" {...props}>
          {t("AppError.backToWebsite")}
        </a>
      )}
    >
      {t("AppError.backToWebsite")}
    </Button>
  </FullscreenLoaderStyle>
);

export default withNamespaces(["common"])(AppError);
