// @flow
import queryString from "query-string";

import { reportError } from "lib/sentry";

const acceptInvite = async (firebase, token, enqueueSnackbar, t) => {
  enqueueSnackbar(t("snackbar.acceptInvite"), {
    variant: "default"
  });
  const acceptInviteFunc = firebase
    .functions()
    .httpsCallable("acceptInviteToCouch");
  try {
    console.log("Accept invite", token);
    await acceptInviteFunc({
      token
    });
    enqueueSnackbar(t("snackbar.acceptInviteSuccess"), {
      variant: "success"
    });
  } catch (err) {
    console.error(err);
    reportError(err);
    enqueueSnackbar(t("snackbar.acceptInviteFailed"), {
      variant: "error"
    });
  }
};

export default ({ firebase, location, history, enqueueSnackbar, t }) => {
  const search = queryString.parse(location.search);
  if (!search.action) {
    return null;
  }
  switch (search.action) {
    case "acceptInvite":
      // change history to prevent loops!
      history.push({
        pathname: location.pathname,
        search: ""
      });
      acceptInvite(firebase, search.token, enqueueSnackbar, t);
      break;
    default:
      reportError(new Error(`Unkown action: ${search.action}`));
  }
  return null;
};
