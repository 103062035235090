// @flow

import React from "react";
import { Helmet } from "react-helmet";

import { withStyles } from "@material-ui/core/styles/index";
import Grid from "@material-ui/core/Grid/Grid";
import Hidden from "@material-ui/core/Hidden/index";
import ActionHandler from "components/ActionHandler";

export type DashboardViewProps = {
  t: Function,
  classes: Object,
  children: any,
  firebase: Object,
  // ui
  ui: {},
  updateUI: Function
  // routes
};

type DashboardViewState = {};

const styles = theme => ({
  desktopContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  content: {
    marginTop: theme.spacing.unit * 9,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,

    [theme.breakpoints.up("md")]: {
      maxWidth: 960,
      paddingLeft: 0,
      paddingRight: 0
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1200
    }
  },
  mobileRoot: {
    height: "100vh"
  }
});

class DashboardView extends React.Component<
  DashboardViewProps,
  DashboardViewState
> {
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <ActionHandler />
        <Helmet title={t("helmetTitle")} />
        <Hidden smUp>{this._renderMobile()}</Hidden>
        <Hidden xsDown>{this._renderDesktop()}</Hidden>
      </React.Fragment>
    );
  }

  _renderMobile = () => {
    const { classes, children } = this.props;
    return <div className={classes.content}>{children}</div>;
  };

  _renderDesktop = () => {
    const { classes, children } = this.props;
    return (
      <div className={classes.desktopContainer}>
        <Grid container justify="center" className={classes.content}>
          <Grid item sm={12}>
            {children}
          </Grid>
        </Grid>
      </div>
    );
  };
}

export default withStyles(styles)(DashboardView);
