import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import createRavenMiddleware from "raven-for-redux";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { reduxFirestore } from "redux-firestore";
import reduxPromiseMiddleware from "redux-promise-middleware";

import makeRootReducer from "./reducers";
// react-redux-firebase config
const rrfConfig = {
  attachAuthIsReady: true,
  userProfile: "users",
  useFirestoreForProfile: true
};

export default (initialState = {}, raven, firebase) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [
    thunk.withExtraArgument({ getFirebase }),
    reduxPromiseMiddleware(),
    createRavenMiddleware(raven)
  ];

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  let composeEnhancers = compose;

  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === "function") {
    composeEnhancers = composeWithDevToolsExtension;
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer({}),
    initialState,
    composeEnhancers(
      reduxFirestore(firebase),
      reactReduxFirebase(firebase, rrfConfig),
      applyMiddleware(...middleware),
      ...enhancers
    )
  );
  store.asyncReducers = {};

  if (module.hot && process.env.NODE_ENV !== "production") {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};
