import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { reducer as reduxUi } from "react-redux-ui-tools";

import appReducer from "ducks/app";
import applicationReducer from "ducks/application";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    ...asyncReducers,
    ui: reduxUi,
    app: appReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    application: applicationReducer
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
