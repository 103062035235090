// @flow
import React from "react";
import { Route, Switch, withRouter } from "react-router";
import Loadable from "react-loadable";

// Pages ###############################################
import DashboardView from "components/DashboardView";
import NotFound from "components/NotFound";

// #####################################################
const LoadableEditCouch = Loadable({
  loading: () => null,
  loader: () => import("components/pages/EditCouch")
});

const LoadableDashboard = Loadable({
  loading: () => null,
  loader: () => import("components/pages/Dashboard")
});

const LoadableApplications = Loadable({
  loading: () => null,
  loader: () => import("components/pages/Applications")
});

const renderWithDashboardView = Component => props => (
  <DashboardView {...props}>
    <Component {...props} />
  </DashboardView>
);

const routes = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}/`}
      render={renderWithDashboardView(LoadableDashboard)}
    />
    <Route
      exact
      path={`${match.url}/editCouch/:couchId`}
      render={renderWithDashboardView(LoadableEditCouch)}
    />
    <Route
      exact
      path={`${match.url}/applications/:couchId`}
      render={renderWithDashboardView(LoadableApplications)}
    />

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
);

export default withRouter(routes);
