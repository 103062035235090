import amplitude from "amplitude-js";

let apiKey = "";

if (process.env.NODE_ENV === "production") {
  apiKey = "1b2a9b607dde50f90911594094b8c929";
} else {
  apiKey = "b99a7bc58070c6a33d0035caa574e996";
}

amplitude.getInstance().init(apiKey);

export default amplitude.getInstance();
