// @flow
import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles/index";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline/index";
import { withErrorBoundary } from "react-error-boundary";
import Loadable from "react-loadable";
import { SnackbarProvider } from "notistack";

import Raven from "lib/sentry";
// $FlowFixMe
import "moment/locale/de";

import { appTheme } from "theme";
import NewVersionDialog from "components/NewVersionDialog";

import AppError from "components/AppError";
import NotFound from "components/NotFound";
import DashboardView from "components/DashboardView";
import Routes from "./Routes";
import PrivateRoute from "./PrivateRoute";

const renderWithDashboardView = Component => props => (
  <DashboardView {...props}>
    <Component {...props} />
  </DashboardView>
);

const LoadableSignIn = Loadable({
  loading: () => null,
  loader: () => import("components/pages/SignIn")
});

const LoadableWesbite = Loadable({
  loading: () => null,
  loader: () => import("components/pages/Homepage")
});

const LoadableApply = Loadable({
  loading: () => null,
  loader: () => import("components/pages/Apply")
});

const LoadableConfirm = Loadable({
  loading: () => null,
  loader: () => import("components/pages/ConfirmApplication")
});

const routes = () => (
  <Switch>
    {/* Website */}
    <Route exact path="/" render={p => <LoadableWesbite {...p} />} />
    {/* AUTH ROUTES */}
    <Route
      exact
      path="/login"
      render={renderWithDashboardView(LoadableSignIn)}
    />
    {/* Apply & Confirm */}
    <Route
      exact
      path={`/c/:couchId`}
      render={props => <LoadableApply {...props} />}
    />
    <Route
      exact
      path={`/confirm-application`}
      render={props => <LoadableConfirm {...props} />}
    />
    {/* APP ROUTES */}
    <PrivateRoute path="/app" render={props => <Routes {...props} />} />
    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
);

const App = () => {
  return (
    <SnackbarProvider>
      <MuiThemeProvider theme={appTheme}>
        <CssBaseline />
        <NewVersionDialog />
        <Router>{routes()}</Router>
      </MuiThemeProvider>
    </SnackbarProvider>
  );
};

const handleBoundaryError = (error, componentStack) => {
  console.error("Boundary error", error);
  Raven.captureException(error, {
    extra: { errorBoundary: true, componentStack }
  });
};

export default withErrorBoundary(App, AppError, handleBoundaryError);
