import makeDebug from 'debug';
import { newVersionAvailable } from 'ducks/app';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/storage';
import registerServiceWorker from 'lib/registerServiceWorker';
import raven from 'lib/sentry';
import tracker from 'lib/tracker';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import createStore from 'store/createStore';
import App from './components/App';
import i18n from './i18n';

const debug = makeDebug('app');

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export default async () => {
  const isDev = process.env.NODE_ENV !== 'production';
  // firebase
  firebase.initializeApp(firebaseConfig);
  if (isDev === false) {
    firebase.functions().region_ = 'europe-west1';
    firebase.functions().region = 'europe-west1';
  }
  firebase.firestore();
  firebase.functions();
  firebase.storage();

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY);
  }
  if (isDev) {
    firebase.functions().useFunctionsEmulator('http://localhost:5000');
  }
  window.firebase = firebase;
  // Store Instantiation
  const initialState = window.__INITIAL_STATE__;
  const store = createStore(initialState, raven, firebase);

  // registerServiceWorker({
  //   // newContentAvailable(registration) {
  //   //   debug('new version available');
  //   //   store.dispatch(newVersionAvailable(registration));
  //   // },
  //   offline() {
  //     debug('offline');
  //   },
  // });

  // User Auth Listener
  firebase.auth().onAuthStateChanged(async (user) => {
    tracker.identify(user || {});
  });

  // Render Setup
  const MOUNT_NODE = document.getElementById('root');

  let render = (AppElement: React.Component): void => {
    ReactDOM.render(
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <AppElement />
        </I18nextProvider>
      </Provider>,
      MOUNT_NODE
    );
  };

  // ======================================================== Go!
  store.firebaseAuthIsReady.then(async () => {
    render(App);
  });

  if (module.hot && process.env.NODE_ENV !== 'production') {
    module.hot.accept('./components/App', () => {
      const NextApp = require('./components/App').default;
      render(NextApp);
    });
  }
};
